<template>
    <div class="page">
        <div class="hzynav w-1350">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{path: '/'}">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{path: '/partner'}">合作伙伴</el-breadcrumb-item>
                <el-breadcrumb-item>{{title}}</el-breadcrumb-item>
                <!-- <el-breadcrumb-item>各国家列表</el-breadcrumb-item> -->
            </el-breadcrumb>
        </div>
        <div class="conter w-1350">
            <div class="center-text">
                <p></p>
                <span>{{text}}</span>
            </div>
            <div class="state-wrap">
                <div class="state" v-for="(item,index) in guojiaList" :key="index">
                    <div class="state-list">
                        <div class="state-item-text">{{item.name}}</div>
                        <div class="state-item" v-for="(item1,index1) in item.cs.slice(0,6)" :key="index1"   @click="goTo('partner_liebiao',{num:num,p_id:item1.p_id,id:item1.id,name:item.name})">
                            <img :src="item1.banner_url" alt="">
                            <p>{{item1.name}}</p>
                        </div>                      
                    </div>
                    <div class="more"  @click="goTo('/partner_item',{key:index,num:num})">
                        <p>更多</p>                         
                        <i class="el-icon-arrow-right"></i>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
    import {getcountrys,getcitys} from '../../api/index';
    export default {
        name:'state_list',
        data() {
            return {
                num:'',
                guojiaList:[],
                title:'',
                text:''
            }
        },
        created() {
            this.num=this.$route.query.num;
            if (this.$route.query.num==1) {
                this.getcountrys()
                this.title="海外合作中心"
                this.text ='各国家列表'
            }else if(this.$route.query.num==0){
                this.getcitys()
                this.title='国内合作中心'
                this.text ='各城市列表'                
            }
             
            
        },
        methods: {    
            // 跳转页面
            goTo(url,query) {
                this.$router.push({ 
                    path: url ,
                    query
                });
            },
            //获取国家列表
            getcountrys(){
                getcountrys().then((res)=>{
                    // console.log(res)
                    if (res.code==200) {
                        this.guojiaList=res.data
                    }
                })
            },
            //获取城市列表
            getcitys(){
                getcitys().then((res)=>{
                    // console.log(res)
                    if (res.code==200) {
                        this.guojiaList=res.data
                    }
                })
            }
        },
    }
</script>

<style lang="less" scoped>
    .page{background: #F4F4F4;overflow: hidden;}
    .hzynav{margin: 35px auto;padding-left: 15px;box-sizing: border-box;}
    .conter{
        margin: auto;margin-bottom:140px;padding:20px 40px 100px 40px;box-sizing: border-box;background:#fff;
        .center-text{
            display: flex;align-items: center;margin-top:45px;
            p{width: 3px;height: 25px;background: #0071DB;}
            span{font-size: 20px;color: #000000;margin-left: 10px;}
        }
        .state-wrap{
            margin-top: 15px;overflow: hidden;
            .state{
                 margin:35px 0;display: flex;align-items: center;justify-content: space-between;
                .state-list{
                    display: flex;align-items: center;flex: 1;
                    .state-item-text{color: #000;font-size: 16px;font-weight: bold;width: 120px;}
                    .state-item{
                        display: flex;align-items: center;font-size:16px;color:#000;cursor: pointer;
                        img{width: 28px;margin-right: 10px;}
                        p{width: 120px;}
                        &:hover{color: #FF7048;text-decoration:underline;}
                    }
                }
                .more{
                    display: flex;align-items: center;color: #FF7048;flex-shrink: 0;cursor: pointer;font-size: 16px;
                    p{margin: 0 5px 1px 0;}
                }
            }

        }
    }

</style>